/*
 * @Description:
 * @Author: tianyu
 * @Email: tianyud@zbj.com
 * @Date: 2021-02-18 22:58:49
 * @LastEditors: tianyu
 * @LastEditTime: 2021-03-23 10:41:46
 */
/**
 * @Description '扩展性强且不破坏url已斜号区分的结构'
 * @method url参数转对象
 */
import session from '@/utils/session'
export function getUrlParams(str?: string) {
  let hrefStr = window.location.href
  if (str === 'lastPath') {
    //获取最后一个路径
    let origin = hrefStr.replace(/(.*?)\?.*/g, '$1')
    let lastPath = origin.split(/\//)
    return lastPath[lastPath.length - 1]
  }
  let search = hrefStr.replace(/.*?\?/g, '')
  let paramsArr = hrefStr !== search ? search.split('&') : []
  let params: any = {}
  for (let p in paramsArr) {
    let paramData = paramsArr[p].split('=')
    params[paramData[0]] = paramData[1] || ''
  }
  return params
}

//全局分页基础信息
export const paginations = {
  current: 1,
  pageSize: 10,
  total: 1,
  showTotal: (total: any) => `共 ${total} 条数据`,
  showSizeChanger: true
}
export function handleTableChange(paginations: any, e: any, callBack: Function) {
  //全局分页
  callBack({ ...paginations, ...e })
}

//获取页面路径
export function pageLocation(url: string, num: number = 0) {
  let urlArr = url.split('/')
  let filterUrl: string[] = []
  let len: number = urlArr.length - num
  urlArr.forEach((item: any, idx) => {
    if (idx < len) {
      filterUrl.push(item)
    }
  })
  return filterUrl.join('/')
}

//时间搓转换
export function timestampToTime(timestamp: number) {
  let date = new Date(timestamp)
  var YY = date.getFullYear() + '-'
  var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
  var DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
  var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
  var ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  return YY + MM + DD + ' ' + hh + mm + ss
}

export function clearLogin() {
  let tokenData: any = session.getLoca('token')
  session.removeLoca('token')
  if (tokenData && tokenData.data.accountType === 1) {
    //admin
    window.location.href = '/#/login?role=admin'
  } else {
    window.location.href = '/#/login'
  }
}
